import React, { createContext, useContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import useGetOrganisationDetail from "../hooks/use-get-organisation-detail";
import { Organisation } from "../types/types";

interface OrganisationContextType {
  organisation: Organisation;
}

const OrganisationContext = createContext({} as OrganisationContextType);

const OrganisationProvider = () => {
  const { organisationName } = useParams();
  const {
    data: organisation,
    isFetching,
    error,
  } = useGetOrganisationDetail(organisationName ?? "", {
    enabled: !!organisationName,
  });

  if (error) {
    return <p>{error.message}</p>;
  }

  if (isFetching && !organisation) {
    return null;
  }

  if (!organisation) {
    return <p>No organisation found with name: {organisationName}</p>;
  }

  return (
    <OrganisationContext.Provider value={{ organisation }}>
      <Outlet />
    </OrganisationContext.Provider>
  );
};

const useOrganisation = () => useContext(OrganisationContext);

export { OrganisationProvider, useOrganisation };
