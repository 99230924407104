import { whitelabelApps } from "../defaults";

export default function getDeeplinkUrl(
  appName: string,
  organisationName: string,
  qrCodeUid: string
) {
  const whitelabelApp = whitelabelApps[appName];
  return `${
    whitelabelApp.deeplink
  }/deeplink/${Date.now()}/organisations/${organisationName}/qr-codes/${qrCodeUid}`;
}
