import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import fetchOrganisationDetail from "../api/fetch-organisation-detail";
import { Organisation } from "../types/types";

export default function useGetOrganisationDetail(
  organisationName: string,
  options?: Partial<UseQueryOptions<Organisation>>
) {
  return useQuery({
    queryKey: ["organisations", organisationName],
    queryFn: () => fetchOrganisationDetail(organisationName),
    ...options,
  });
}
