import {
  QrCodeCardContentModel,
  QrCodeChapterContentModel,
  QrCodeCollectionContentModel,
  QrCodeContentModelTypes,
  QrCodeContentResponseTypes,
  QrCodePlaybookContentModel,
} from "../models/qr-code-content.model";
import axios from "axios";
import ContentTypesEnum from "../enums/content-types.enum";

export async function fetchQrContent(
  baseURL: string,
  qrCodeUid: string
): Promise<QrCodeContentModelTypes> {
  const { data } = await axios.request<QrCodeContentResponseTypes>({
    baseURL,
    url: `/qr-codes/${qrCodeUid}/content`,
    method: "GET",
  });
  switch (data.contentType) {
    case ContentTypesEnum.PLAYBOOK:
      return new QrCodePlaybookContentModel(data);
    case ContentTypesEnum.CHAPTER:
      return new QrCodeChapterContentModel(data);
    case ContentTypesEnum.COLLECTION:
      return new QrCodeCollectionContentModel(data);
    case ContentTypesEnum.CARD:
      return new QrCodeCardContentModel(data);
  }
}
