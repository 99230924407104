import { Organisation } from "../types/types";
import axios from "axios";

export default async function fetchOrganisationDetail(
  organisationName: string
) {
  const { data } = await axios.get<Organisation>(
    `https://organisations.primio.app/organisations/${organisationName}`,
    {
      headers: {
        Authentication: `Bearer ${process.env.REACT_APP_ORGANISATIONS_TOKEN}`,
      },
    }
  );
  return data;
}
