import IconPrimio from "./assets/icon_primio.png";
import IconPrimioSandbox from "./assets/icon_primio_sandbox.png";
import IconHumio from "./assets/icon_humio.png";
import IconVRBZO from "./assets/icon_vrbzo.png";
import IconHups from "./assets/icon_hups.png";

interface WhitelabelApp {
  deeplink: string;
  android: string;
  ios: string;
  icon: string;
  name: string;
}

export const whitelabelApps: { [key: string]: WhitelabelApp } = {
  Primio: {
    deeplink: "primio-v2://",
    android: "https://play.google.com/store/apps/details?id=app.primio.v2",
    ios: "https://apps.apple.com/nl/app/primio/id1589413386",
    icon: IconPrimio,
    name: "Primio",
  },
  "Primio Sandbox": {
    deeplink: "primio-sandbox://",
    android: "https://play.google.com/store/apps/details?id=app.primio.sandbox",
    ios: "https://apps.apple.com/us/app/primio/id1607527932",
    icon: IconPrimioSandbox,
    name: "Primio Sandbox",
  },
  Humio: {
    deeplink: "humio://",
    android: "https://play.google.com/store/apps/details?id=com.humio.humio",
    ios: "https://apps.apple.com/us/app/humio/id1567437070",
    icon: IconHumio,
    name: "Humio",
  },
  "Veiligheidsregio kennis-app": {
    deeplink: "veiligheidsregio-kennis-app://",
    android:
      "https://play.google.com/store/apps/details?id=app.primio.veiligheidsregio",
    ios: "https://apps.apple.com/nl/app/veiligheidsregio-kennis-app/id1670089446",
    icon: IconVRBZO,
    name: "VRBZO kennis-app",
  },
  Hups: {
    deeplink: "primio-hups://",
    android: "https://play.google.com/store/apps/details?id=app.primio.hups",
    ios: "https://apps.apple.com/nl/app/hups-academy/id6476095614",
    icon: IconHups,
    name: "Hups",
  },
};
