import SourceModel, { SourceResponse } from "./sources/source.model";
import BaseMediaContentModel, {
  BaseMediaContentResponse,
} from "./media/base-media-content.model";
import ContentTypesEnum from "../enums/content-types.enum";
import { MapResponseToModel } from "../utils/map-response-to-model.util";

export interface BaseCardResponse extends BaseMediaContentResponse {
  cardUid: string;
  version: number;
  collectionUid: string;
  mediaUid?: string;
  sources?: SourceResponse[];
}

export default class BaseCardModel extends BaseMediaContentModel {
  cardUid: string;
  collectionUid: string;
  contentType: ContentTypesEnum.CARD;
  version: number;
  sources: SourceModel[];

  constructor(card: BaseCardResponse) {
    super(card);
    this.cardUid = card.cardUid;
    this.version = card.version;
    this.collectionUid = card.collectionUid;
    this.contentType = ContentTypesEnum.CARD;
    this.sources =
      card.sources && card.sources.length > 0
        ? MapResponseToModel(card.sources, SourceModel)
        : [];
  }
}
