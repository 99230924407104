import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBackground } from "../providers/background.provider";
import Card from "../components/card/card";
import PrimioLogo from "../assets/Primio_Full_W.png";
import { useQrCodeContent } from "../providers/qr-code-content.provider";
import CollectionModel from "../models/content/collection.model";
import { useOrganisation } from "../providers/organisation.provider";

const CollectionDetailScreen = () => {
  const { setBackgroundColor } = useBackground();
  const { collectionUid, cardUid } = useParams();
  const [collection, setCollection] = useState<CollectionModel>();
  const { qrCodeContent } = useQrCodeContent();
  const [highlightedCardUid, setHighlightedCardUid] = useState<string>();
  const {
    organisation: {
      data: {
        theme: {
          collection: { background },
        },
      },
    },
  } = useOrganisation();

  useEffect(() => {
    if (!collectionUid) return;

    qrCodeContent.playbook.chapters.forEach((chapter) => {
      const collection = chapter.collections.find(
        (collection) => collection.collectionUid === collectionUid
      );
      if (collection) {
        setCollection(collection);
      }
    });
  }, [qrCodeContent, collectionUid]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function scroll(id: string) {
    const ele = document.getElementById(id);
    if (!ele) return;

    setTimeout(() => {
      ele.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
      setHighlightedCardUid(cardUid);
    }, 200);
  }

  useEffect(() => {
    setBackgroundColor(background);
  }, [setBackgroundColor, background]);

  useEffect(() => {
    if (!collection || !cardUid) return;

    scroll(cardUid);
  }, [collection, cardUid, scroll]);

  if (!collection) {
    // TODO: show proper error
    return (
      <div>
        <p>no collection</p>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="px-2">
        <h1
          style={{
            color: "white",
            textAlign: "center",
            marginTop: 40,
            marginBottom: 40,
          }}
        >
          {collection.title}
        </h1>

        <div className="pb-10 px-2">
          <Card
            title={collection.startSummary.title}
            description={collection.startSummary.description}
            media={collection.startSummary.media}
          />
        </div>

        {collection.cards.map((card, index) => {
          const isHighLighted = highlightedCardUid === card.cardUid;

          return (
            <div key={index} id={card.cardUid} className="pb-10">
              <div
                className="border border-solid rounded-lg border-2 mx-1 p-1 transition-all delay-500 duration-500"
                style={{ borderColor: isHighLighted ? "white" : "transparent" }}
              >
                <Card
                  title={card.title}
                  description={card.description}
                  media={card.media}
                />
              </div>
            </div>
          );
        })}

        <div className="px-2">
          <Card
            title={collection.endSummary.title}
            description={collection.endSummary.description}
            media={collection.endSummary.media}
          />
        </div>
      </div>

      <div className="px-4 py-8">
        <p className="text-xs text-center mb-3 opacity-50 text-white">
          Powered by
        </p>
        <a target="_blank" href="https://www.primio.app" rel="noreferrer">
          <img
            className="mx-auto"
            alt="Primio logo"
            src={PrimioLogo}
            style={{ width: 116 }}
          />
        </a>
      </div>
    </div>
  );
};

export default CollectionDetailScreen;
