import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { BackgroundProvider } from "./providers/background.provider";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const container = document.getElementById("root")!;
const root = createRoot(container);

Sentry.init({
  dsn: "https://f25f8ce2a9bf4e6088402fadccdc1568@o307090.ingest.sentry.io/6520369",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: false,
});

root.render(
  <React.StrictMode>
    <BackgroundProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </BackgroundProvider>
  </React.StrictMode>
);
