import React from "react";
import { Link } from "react-router-dom";
import CollectionModel from "../../models/content/collection.model";
import { useOrganisation } from "../../providers/organisation.provider";

const Collection = ({ collection }: { collection: CollectionModel }) => {
  const {
    organisation: {
      data: {
        theme: { primary },
      },
    },
  } = useOrganisation();
  return (
    <Link to={`collections/${collection.collectionUid}`}>
      <div
        className="bg-white drop-shadow-sm p-4 rounded-lg border-2 border-solid hover:bg-gray-50"
        style={{ borderColor: "#E8E8E8" }}
      >
        <div className="flex">
          <div className="flex-1 min-w-0 justify-center content-center">
            <h3 className="whitespace-nowrap overflow-hidden text-ellipsis">
              {collection.title}
            </h3>
            {collection.description && collection.description.trim() !== "" && (
              <p className="mt-1 opacity-40 whitespace-nowrap overflow-hidden text-ellipsis">
                {collection.description}
              </p>
            )}
          </div>
          <div className="flex justify-center content-center items-center">
            <div
              className="bg-blue rounded-full text-center relative flex justify-center items-center"
              style={{ height: 32, width: 32, backgroundColor: primary }}
            >
              <p className="ant-design text-white" style={{ fontSize: 16 }}>
                
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Collection;
