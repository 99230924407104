import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { fetchQrContent } from "../api/fetch-qr-code-content";
import { QrCodeContentModelTypes } from "../models/qr-code-content.model";

export default function useGetQrCodeContent(
  baseURL: string,
  qrCodeUid: string,
  options?: Partial<UseQueryOptions<QrCodeContentModelTypes>>
) {
  return useQuery({
    queryKey: ["qr-codes", qrCodeUid],
    queryFn: () => fetchQrContent(baseURL, qrCodeUid),
    ...options,
  });
}
