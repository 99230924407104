import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { OrganisationProvider } from "./providers/organisation.provider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  QrCodeContentProvider,
  useQrCodeContent,
} from "./providers/qr-code-content.provider";
import ContentTypesEnum from "./enums/content-types.enum";
import PlaybookDetailScreen from "./screens/playbook-detail.screen";
import CollectionDetailScreen from "./screens/collection-detail.screen";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route index element={<Root />} />
        <Route
          path={"organisations/:organisationName"}
          element={<OrganisationProvider />}
        >
          <Route
            path={"qr-codes/:qrCodeUid"}
            element={<QrCodeContentProvider />}
          >
            <Route index element={<QrCodeContentRoot />} />
            <Route
              path={"playbooks/:playbookUid"}
              element={<PlaybookDetailScreen />}
            />
            <Route
              path="playbooks/:playbookUid/collections/:collectionUid"
              element={<CollectionDetailScreen />}
            />
            <Route
              path="playbooks/:playbookUid/collections/:collectionUid/cards/:cardUid"
              element={<CollectionDetailScreen />}
            />
          </Route>
        </Route>
      </Routes>
    </QueryClientProvider>
  );
}

const Root = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string>();

  useEffect(() => {
    // The 'e' param is legacy naming, comes from time when we called organisations 'environments'.
    const organisationName = searchParams.get("e");
    const qrCodeUid = searchParams.get("q");

    if (!qrCodeUid || !organisationName) {
      setError("No search params provided");
      return;
    }

    navigate(`organisations/${organisationName}/qr-codes/${qrCodeUid}`);
  }, [searchParams, navigate]);

  if (error) {
    return <p>{error}</p>;
  }

  return null;
};

const QrCodeContentRoot = () => {
  const { qrCodeContent } = useQrCodeContent();
  const navigate = useNavigate();

  useEffect(() => {
    switch (qrCodeContent.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        navigate(`playbooks/${qrCodeContent.data.playbookUid}`);
        break;
      case ContentTypesEnum.COLLECTION:
        navigate(
          `playbooks/${qrCodeContent.data.playbookUid}/collections/${qrCodeContent.data.collectionUid}`
        );
        break;
      case ContentTypesEnum.CARD:
        navigate(
          `playbooks/${qrCodeContent.data.playbookUid}/collections/${qrCodeContent.data.collectionUid}/cards/${qrCodeContent.data.cardUid}`
        );
        break;
    }
  }, [qrCodeContent, navigate]);

  return null;
};

export default App;
