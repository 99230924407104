import React from "react";
import { useOrganisation } from "../../providers/organisation.provider";
import { useParams } from "react-router-dom";
import { whitelabelApps } from "../../defaults";
import getDeeplinkUrl from "../../utils/get-deeplink-url";

function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/ipad|iphone|ipod/.test(userAgent)) {
    return "iOS";
  } else if (/android/i.test(userAgent)) {
    return "Android";
  } else {
    return "Unknown";
  }
}
function isIOS() {
  return getMobileOperatingSystem() === "iOS";
}

function isAndroid() {
  return getMobileOperatingSystem() === "Android";
}

const Banner = () => {
  const {
    organisation: {
      data: {
        appName,
        theme: { primary },
      },
    },
  } = useOrganisation();
  const { organisationName, qrCodeUid } = useParams();
  const whitelabelApp = whitelabelApps[appName];

  const handleOpen = () => {
    if (!organisationName || !qrCodeUid) {
      return;
    }

    window.location.href = getDeeplinkUrl(appName, organisationName, qrCodeUid);
  };
  const handleInstall = () => {
    if (isIOS()) {
      window.location.href = whitelabelApp.ios;
    } else if (isAndroid()) {
      window.location.href = whitelabelApp.android;
    }
  };

  if (!whitelabelApp || (!isIOS() && !isAndroid())) {
    // platform is not mobile
    return null;
  }

  return (
    <div className="container bg-white p-3 sticky top-0 left-0 right-0 z-50 flex-row flex shadow-md items-center">
      <img
        src={whitelabelApp.icon}
        alt={whitelabelApp.name}
        className="w-9 h-9 mr-2 border rounded"
      />

      <div className="flex flex-1 flex-col mr-4">
        <p className="font-bold text-sm">{whitelabelApp.name}</p>
        <p>Voor {getMobileOperatingSystem()}</p>
      </div>
      <button
        className="p-2 bg-gray-800 text-white font-semibold mr-2 rounded cursor-pointer"
        onClick={handleOpen}
      >
        Open
      </button>
      <button
        className="p-2 text-white font-semibold rounded cursor-pointer"
        style={{ backgroundColor: primary }}
        onClick={handleInstall}
      >
        Installeer
      </button>
    </div>
  );
};

export default Banner;
