import React, { createContext, useContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useOrganisation } from "./organisation.provider";
import useGetQrCodeContent from "../hooks/use-get-qr-code-content";
import { QrCodeContentModelTypes } from "../models/qr-code-content.model";
import Banner from "../components/banner/banner";
import getDeeplinkUrl from "../utils/get-deeplink-url";

interface QrCodeContentProviderType {
  qrCodeContent: QrCodeContentModelTypes;
}

const QrCodeContentContext = createContext({} as QrCodeContentProviderType);

const QrCodeContentProvider = () => {
  let { qrCodeUid } = useParams();
  const {
    organisation: {
      name,
      data: {
        appApiUrl,
        appName,
        theme: { primary },
      },
    },
  } = useOrganisation();
  const {
    data: qrCodeContent,
    isFetching,
    error,
  } = useGetQrCodeContent(appApiUrl, qrCodeUid ?? "", { enabled: !!qrCodeUid });

  if (error && (error as any).status === 404) {
    return (
      <div>
        <Banner />
        <div className="m-7 mt-8">
          <div className="flex flex-col items-center">
            <div
              className="rounded-full bg-gray-300 aspect-square flex flex-col items-center justify-center"
              style={{ height: 80, width: 80 }}
            >
              <span className="text-center text-4xl">🔒</span>
            </div>
          </div>
          <h1 className="mt-6 mb-2 leading-normal">
            Deze informatie kan je alleen bekijken in de {appName} app.
          </h1>

          <p className="mt-4 mb-6 leading-normal text-base">
            Zorg ervoor dat je de {appName} app hebt en bent ingelogd bij de
            juiste organisatie. Is dat het geval? Druk dan op de knop hieronder:
          </p>

          <div className="flex flex-col items-center">
            <button
              className="p-3 px-6 text-center text-xl text-white rounded-md font-bold"
              style={{ background: primary }}
              onClick={() => {
                if (!qrCodeUid) {
                  return;
                }

                window.location.href = getDeeplinkUrl(appName, name, qrCodeUid);
              }}
            >
              Open in {appName}
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Banner />
        <div className="m-7 mt-8">
          <div className="flex flex-col items-center">
            <div
              className="rounded-full bg-gray-300 aspect-square flex flex-col items-center justify-center"
              style={{ height: 80, width: 80 }}
            >
              <span className="text-center text-4xl">⚠️</span>
            </div>
          </div>
          <h1 className="mt-6 mb-2 leading-normal">Oops er ging iets fout.</h1>

          <p className="mt-4 leading-normal text-base">{error.message}</p>
        </div>
      </div>
    );
  }

  if (isFetching && !qrCodeContent) {
    return null;
  }

  if (!qrCodeContent) {
    return (
      <div>
        <Banner />
        <div className="m-7 mt-8">
          <div className="flex flex-col items-center">
            <div
              className="rounded-full bg-gray-300 aspect-square flex flex-col items-center justify-center"
              style={{ height: 80, width: 80 }}
            >
              <span className="text-center text-4xl">⚠️</span>
            </div>
          </div>
          <h1 className="mt-6 mb-2 leading-normal">Geen content gevonden</h1>

          <p className="mt-4 leading-normal text-base">
            Deze QR-code lijkt niet te verwijzen naar bestaande content.
            Controleer of je de juiste code hebt gescand of probeer het later
            opnieuw.
          </p>
        </div>
      </div>
    );
  }

  return (
    <QrCodeContentContext.Provider value={{ qrCodeContent }}>
      <Banner />
      <Outlet />
    </QrCodeContentContext.Provider>
  );
};

const useQrCodeContent = () => useContext(QrCodeContentContext);

export { QrCodeContentProvider, useQrCodeContent };
