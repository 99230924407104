import React from "react";
import MediaModel from "../../models/media/media.model";
import {
  AudioProvidersEnum,
  EmbedProvidersEnum,
  ImageProvidersEnum,
  MediaTypesEnum,
  VideoProvidersEnum,
} from "../../enums/media-providers.enum";

const Media = ({ media }: { media: MediaModel }) => {
  const getElement = () => {
    switch (media.mediaType) {
      case MediaTypesEnum.NO_MEDIA:
        return null;
      case MediaTypesEnum.AUDIO:
        switch (media.provider) {
          case AudioProvidersEnum.S3:
            return (
              <div className="p-4 bg-gray-100">
                <audio style={{ width: "100%" }} controls src={media.uri} />
              </div>
            );
          case AudioProvidersEnum.SPOTIFY:
            return null;
        }
        return null;
      case MediaTypesEnum.VIDEO:
        switch (media.provider) {
          case VideoProvidersEnum.S3:
            return <video controls width="100%" src={media.uri} />;
          case VideoProvidersEnum.VIMEO:
            return (
              <iframe
                title={"Vimeo video player"}
                src={`https://player.vimeo.com/video/${media.uri}?h=86d3037644&color=e1141d&title=0&byline=0&portrait=0`}
                width="100%"
                height="240"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            );
          case VideoProvidersEnum.YOUTUBE:
            return (
              <iframe
                width="100%"
                height="240"
                src={`https://www.youtube.com/embed/${media.uri}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            );
          case VideoProvidersEnum.HIHAHO:
            return null;
        }
        break;
      case MediaTypesEnum.IMAGE:
        switch (media.provider) {
          case ImageProvidersEnum.S3:
          case ImageProvidersEnum.LOCAL:
            return (
              <img
                alt={media.provider}
                src={media.uri}
                className={"h-full w-full object-cover"}
              />
            );
          case ImageProvidersEnum.UNSPLASH:
            return (
              <img
                alt={media.provider}
                src={`https://images.unsplash.com/${media.uri}`}
                className={"h-full w-full object-cover"}
              />
            );
          case ImageProvidersEnum.GIPHY:
            return (
              <img
                alt={media.provider}
                src={`https://i.giphy.com/${media.uri}.gif`}
                className={"h-full w-full object-cover"}
              />
            );
        }
        break;
      case MediaTypesEnum.EMBED:
        switch (media.provider) {
          case EmbedProvidersEnum.CODE:
            return <div dangerouslySetInnerHTML={{ __html: media.uri }} />;
          case EmbedProvidersEnum.LINK:
            return (
              <div className="flex justify-center content-center py-7 bg-gray-100">
                <a
                  className="relative inline-block rounded-xl px-5 py-3 drop-shadow-md border border-solid border-amber-700 transition-colors bg-amber-400 hover:bg-amber-500"
                  href={media.uri}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="text-base font-bold text-center">
                    Externe website
                  </p>
                  <p className="text-xs text-center font-mono text-amber-800">
                    {media.uri.substring(0, 30)}
                  </p>
                </a>
              </div>
            );
        }
    }
  };

  return <>{getElement()}</>;
};

export default Media;
