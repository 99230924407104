import React, { useEffect } from "react";
import Media from "../components/media";
import { useBackground } from "../providers/background.provider";
import Collection from "../components/collection/collection";
import PrimioLogo from "./../assets/Primio_Full.png";
import { useQrCodeContent } from "../providers/qr-code-content.provider";
import { useOrganisation } from "../providers/organisation.provider";

const PlaybookDetailScreen = () => {
  const { setBackgroundColor } = useBackground();
  const { qrCodeContent } = useQrCodeContent();
  const {
    organisation: {
      data: {
        theme: { background },
      },
    },
  } = useOrganisation();

  useEffect(() => {
    setBackgroundColor("#EEEDED");
  }, [setBackgroundColor]);

  const { playbook } = qrCodeContent;

  return (
    <div
      className="container bg-white min-h-screen"
      style={{ backgroundColor: background }}
    >
      {playbook.media && playbook.media[0] ? (
        <div className="relative mb-4">
          <div className="w-full relative" style={{ paddingTop: "56.25%" }}>
            <div className="absolute top-0 bottom-0 left-0 right-0 user-select-none pointer-events-none">
              <Media media={playbook.media[0]} />
            </div>
          </div>

          <div className="overflow-hidden relative">
            <div className="absolute bottom-0">
              <Media media={playbook.media[0]} />
            </div>
            <div className="backdrop-blur-2xl px-4 py-6 bg-white/60 relative">
              <h1>{playbook.title}</h1>
              {playbook.description && playbook.description.trim() !== "" && (
                <p className="mt-1">{playbook.description}</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="px-4 pt-6 mb-7">
          <h1 className="mb-1">{playbook.title}</h1>
          <p>{playbook.description}</p>
        </div>
      )}

      <div className="px-4">
        {playbook.chapters.map((chapter, index) => (
          <div key={index} className={index !== 0 ? "mt-8" : undefined}>
            <div className="mb-3">
              <h2 className="mb-1">{chapter.title}</h2>
              <p>{chapter.description}</p>
            </div>

            {chapter.collections.map((collection, index) => (
              <div key={index} className={index !== 0 ? "mt-2" : undefined}>
                <Collection collection={collection} />
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="px-4 py-8">
        <p className="text-xs text-center mb-3 opacity-30">Powered by</p>
        <a target="_blank" href="https://www.primio.app" rel="noreferrer">
          <img
            className="mx-auto"
            alt="Primio logo"
            src={PrimioLogo}
            style={{ width: 116 }}
          />
        </a>
      </div>
    </div>
  );
};

export default PlaybookDetailScreen;
