import React from "react";
import Media from "../media";
import MediaModel from "../../models/media/media.model";
import { useOrganisation } from "../../providers/organisation.provider";

interface CardProps {
  title: string;
  description?: string;
  media?: MediaModel[];
}

const Card = ({ title, description, media }: CardProps) => {
  const {
    organisation: {
      data: {
        theme: {
          collection: { foreground },
        },
      },
    },
  } = useOrganisation();
  return (
    <div
      className="bg-white rounded-lg drop-shadow-2xl"
      style={{ minHeight: 250, backgroundColor: foreground }}
    >
      {media && media[0] && (
        <div
          className="rounded-t-lg overflow-hidden relative"
          style={{ aspectRatio: "16/9" }}
        >
          <Media media={media[0]} />
        </div>
      )}
      <div className="p-4">
        <h3 className="mb-1">{title}</h3>
        {description ? (
          description.includes("</") ? (
            <p dangerouslySetInnerHTML={{ __html: description }} />
          ) : (
            <p>{description}</p>
          )
        ) : null}
      </div>
    </div>
  );
};

export default Card;
